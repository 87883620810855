"use client"
import { ModeToggle } from "@/app/components/DarkMode"
import Head from "next/head"
import Link from "next/link"

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/app/components/ui/navigation-menu"

const Header = () => {
  return (
    <>
      <Head>
        <title>PARS</title>
      </Head>

      <NavigationMenu className="z-20 h-20 max-h-20 w-full max-w-full flex-none justify-between bg-blue-900 text-slate-200 dark:bg-blue-950">
        <NavigationMenuList>
          <NavigationMenuItem>
            <Link href="/">
              <div className="flex ">
                {/*                 <Image
                  priority
                  src={doeLogo}
                  alt="DOE Logo"
                  width={60}
                  height={60}
                  className="mx-2"
                /> */}

                <div className="mx-4 mb-2 scroll-m-20 text-5xl font-extrabold lg:text-6xl">
                  PARS
                </div>
              </div>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <NavigationMenuLink
              href="https://support.pars.doe.gov/"
              className={navigationMenuTriggerStyle()}
            >
              Help
            </NavigationMenuLink>
          </NavigationMenuItem>
        </NavigationMenuList>
        <NavigationMenuList className="self-end">
          <NavigationMenuItem>
            <ModeToggle />
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </>
  )
}

export default Header
